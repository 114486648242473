.splash-screen {
  background-color: #e3ecec;
  z-index: 10000000;
  position: fixed;
}

.splash-screen-dark {
  background-color: #12141d;
  z-index: 10000000;
  position: fixed;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes show-rect {
  to {
    visibility: visible;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1; /* Set fill opacity to 1 (fully visible) */
  }
}

@keyframes fill-in-dot {
  99% {
    fill-opacity: 0; /* Set fill opacity to 1 (fully visible) */
  }
  100% {
    visibility: hidden;
  }
}

.path-smart {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
  animation: dash 5s forwards;
}

.splash-screen .path-smart {
  fill: #12141d;
}

.splash-screen-dark .path-smart {
  fill: #e3ecec;
}

.path-docs {
  fill: #eb5c37;
  stroke-dasharray: 550;
  stroke-dashoffset: 550;
  animation: dash 5s forwards;
}

.path-dot {
  fill: #eb5c37;
  stroke-dasharray: 120;
  stroke-dashoffset: 120;
  animation: dash 5s forwards;
}

.g-smart {
  fill: #000;
  fill-rule: nonzero;
  animation: fill-in 1s 2s forwards;
  fill-opacity: 0;
}

.splash-screen .g-smart {
  stroke: #12141d;
}

.splash-screen-dark .g-smart {
  stroke: #e3ecec;
}

.g-docs {
  stroke: #eb5c37;
  animation: fill-in 1s 2s forwards;
  fill-opacity: 0;
}

.rect-dot {
  fill: #eb5c37;
  stroke: #eb5c37;
  visibility: hidden;
  animation: show-rect 0s 2s forwards;
}

.rect-dot-inner {
  visibility: hidden;
  animation: show-rect 0s 2s forwards;
}

.splash-screen .rect-dot-inner {
  fill: #e3ecec;
}

.splash-screen-dark .rect-dot-inner {
  fill: #12141d;
}

.g-dot {
  stroke: #eb5c37;
  fill-opacity: 0;
  animation: fill-in-dot 1s 2s forwards;
}
